//
// faq.scss
// Incline component
//

.faq-item {
  padding: $faq-item-padding;

  + .faq-item {
    border-top: 1px solid $border-color;
  }
}

// Heading

.faq-item-heading {
  display: flex;
  align-items: center;
  color: $body-color;
  transition: $transition-base;

  // Sign

  &:before {
    $size: 1.5rem;
    content: '+';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $size; height: $size;
    min-width: $size;
    margin-right: 1rem;
    background-color: $dark;
    font-weight: bold;
    color: white;
    transition: inherit;
  }

  // Hover

  &:hover {
    text-decoration: none;
    color: $link-hover-color;

    &:before {
      background-color: $link-hover-color;
    }
  }

  // Expanded

  &[aria-expanded="true"] {

    &:before {
      content: '-';
    }
  }
}

// Content

.faq-item-content {
  color: $text-muted;
}

.faq-item-content-inner {
  padding-top: 1rem;
}