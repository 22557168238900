// SiteWare form stylings

label.required:after {
  content: ' (Pflichtfeld)';
}

.strikeout {
  text-decoration: line-through;
}

.invalid-feedback-before-checkbox {
  display: block;
  padding: 0;
  border: none;
  color: $form-feedback-invalid-color;
  background-color: transparent;
}
