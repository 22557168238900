//
// background.scss
// Extended from Bootstrap
//

// Slider

.bg-slider {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  z-index: auto;
  
  // Slider

  .slider {
    width: 100%; height: 100%;

    // Item

    &-item {
      width: inherit; height: inherit;
      padding: 0;
    }

    // Flickity

    .flickity {

      // Viewport

      &-viewport {
        height: 100% !important;
      }
    }
  }
}


// Video

.bg-video {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  overflow: hidden;

  // Media

  &-media {
    position: absolute;
    top: 50%; left: 50%;
    min-width: 100%; min-height: 100%;
    transform: translate(-50%, -50%);
    
    @supports not( -ms-ime-align: auto) {

      @supports (object-fit: cover) {
        top: 0; left: 0;
        width: 100%; height: 100%;
        min-width: none; min-height: none;
        object-fit: cover;
        transform: none;
      }
    }
  }
}


// Cover

.bg-cover {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background: {
    repeat: no-repeat;
    position: center center;
    size: cover;
  }
}


// Overlay

.bg-overlay {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: $bg-overlay-bg;
    opacity: $bg-overlay-opacity;
  }
}


// Triangle

.bg-triangle {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% auto;

  // Left

  &-left {

    // Dark

    background-image: 
        str-replace(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><path d='M0 100 V 90 L 10 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 3}'></path><path d='M0 90 V 80 L 10 90 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 4.5}'></path><path d='M10 100 V 90 L 20 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 2.5}'></path><path d='M0 90 H 10 V 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 6}'></path></svg>"), "#", "%23");
    
    // Light

    &.bg-triangle-light {

      background-image: 
        str-replace(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><path d='M0 100 V 90 L 10 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 3}'></path><path d='M0 90 V 80 L 10 90 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 4.5}'></path><path d='M10 100 V 90 L 20 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 2.5}'></path><path d='M0 90 H 10 V 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 6}'></path></svg>"), "#", "%23");
    }
  }

  // Right

  &-right {

    // Dark

    background-image: 
          str-replace(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><path d='M100 100 V 90 L 90 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 4.5}'></path><path d='M100 90 V 80 L 90 90 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 1.5}'></path><path d='M90 100 V 90 L 80 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 3}'></path><path d='M90 100 V 90 H 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 6}'></path></svg>"), "#", "%23");

    // Light

    &.bg-triangle-light {

      background-image: 
            str-replace(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><path d='M100 100 V 90 L 90 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 4.5}'></path><path d='M100 90 V 80 L 90 90 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 1.5}'></path><path d='M90 100 V 90 L 80 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 3}'></path><path d='M90 100 V 90 H 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 6}'></path></svg>"), "#", "%23");
    }
  }

  // Top

  &-top {
    transform: scaleY(-1);
  }
}


// Incline

.bg-incline {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background-repeat: no-repeat;
  background-position: 
    top right,
    top right,
    calc(80% - #{$bg-incline-size * .2}) top,
    calc(90% - #{$bg-incline-size * .1}) top;
  background-size: 
    20% 100%,
    10% 100%,
    $bg-incline-size $bg-incline-size,
    $bg-incline-size $bg-incline-size;

  // Disable in IE

  @media all and (-ms-high-contrast:none) {
    background-size: 0 0;
  }

  // Dark

  &-dark {

    background-image:
      linear-gradient(to right, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 0%, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 100%),
      linear-gradient(to right, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 0%, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 100%),
      linear-gradient(to top left, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 50%, transparent 50%),
      linear-gradient(to top left, fade-out($bg-incline-dark-bg, (1 - $bg-incline-dark-opacity)) 50%, transparent 50%);
  }

  // Light
  
  &-light {

    background-image:
      linear-gradient(to right, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 0%, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 100%),
      linear-gradient(to right, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 0%, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 100%),
      linear-gradient(to top left, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 50%, transparent 50%),
      linear-gradient(to top left, fade-out($bg-incline-light-bg, (1 - $bg-incline-light-opacity)) 50%, transparent 50%);
  }

  // Right

  // Nothing to change here. All good.

  // Left

  &-left {
    transform: scaleX(-1);
  }

  // Bottom

  // Nothing to change here. All good.

  // Top

  &-top {
    transform: scaleY(-1);

    &.bg-incline-left {
      transform: scale(-1);
    }
  }
}