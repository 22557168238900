//
// avatar.scss
// Theme component
//

.avatar {
  display: inline-block;
  width: $avatar-size; height: $avatar-size;
}

.avatar-lg {
  width: $avatar-size-lg; height: $avatar-size-lg;
}

.avatar-sm {
  width: $avatar-size-sm; height: $avatar-size-sm;
}