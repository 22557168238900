// begin::Fonts
// store fonts locally (gdpr)
//@import '../stylesheets/fonts';
// end::Fonts
 //
//
// theme.scss
//
// Custom variables followed by theme variables followed by Bootstrap variables
// to ensure cascade of styles.
//

// Bootstrap functions
@import '~bootstrap/scss/functions';

// Theme functions
@import '../stylesheets/incline/functions';

// Theme variables
@import '../stylesheets/incline/variables';

// Bootstrap core
@import '../stylesheets/bootstrap-custom';

// Plugins CSS
@import 'flickity';
@import 'flickity-fade/flickity-fade';
@import 'fullpage.js/dist/fullpage';
@import 'highlight.js/scss/codepen-embed';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
//@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '../inclineicons/style';
@import 'leaflet';

@import '../../../lib/rails-bootstrap-forms/app/assets/stylesheets/rails_bootstrap_forms';
@import 'select2';
@import 'select2-bootstrap4-theme';
@import '../stylesheets/bootstrap/select2-custom';
@import '../stylesheets/tempusdominus-bootstrap-4-build';

// Theme core
@import '../stylesheets/incline/theme';

// import specific styles for controllers/views
@import '../stylesheets/controllers';

.frontpage {
  #slideHeading1x0 {
    background-image: url(../images/gartenheim-rounded.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    padding: 0;
    margin: 0;
    height: 9vh;

    span {
      visibility: hidden;
    }
  }
}

.body_end {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
