// Import bootstrap variables including default color palette and fonts
@import '../../../node_modules/bootstrap/scss/_functions.scss';
@import '../../../node_modules/bootstrap/scss/_variables.scss';

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Import datepicker component
//@import './bootstrap/tempusdominus-bootstrap-4';
@import 'tempusdominus-bootstrap-4/src/sass/_tempusdominus-bootstrap-4';

// fix a bug in datetimepicker together with animations of incline:
.input-group.date > .dropdown-menu {
  opacity: 1 !important;
}
