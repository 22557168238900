//
// stats.scss
// Incline component
//

.stats-item {
  position: relative;
  
  // Line

  &:before {
    content: '';
    display: block;
    width: .5rem; height: .5rem;
    margin: 0 auto 1.5rem;
    background-color: $primary;
  }
}

// Value

.stats-item-value {
  font-size: $stats-item-val-font-size;
}

// Title

.stats-item-title {
  font-size: $stats-item-title-font-size;
  font-weight: $stats-item-title-font-weight;
  letter-spacing: $stats-item-title-letter-spacing;
  text-transform: uppercase;
}