// SiteWare select2 stylings

// select2 tags
.select2-container--bootstrap4 {
  .select2-selection--multiple {
    .select2-selection__rendered {
      padding: 0.25rem 0.25rem 0.5rem 0.5rem;
    }
    .select2-selection__choice {
      padding-top: 0.25em;
    }
    .select2-selection__choice__remove {
      float: left;
      color: $primary;
      background-color: transparent;
      border: 0;
      opacity: .5;
      font-size: 1.40625rem;
      font-weight: 600;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      margin: 0;
      padding: 0.125rem 0.5rem 0 0;
    }
  }
}
