//
// type.scss
// Extended from Bootstrap
//

// Import fonts

@font-face {
  font-family: "Objective";
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url(../../fonts/Objective/Objective-Regular.woff2) format("woff2"); /* Super Modern Browsers */
}

@font-face {
  font-family: "Objective";
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url(../../fonts/Objective/Objective-Medium.woff2) format("woff2"); /* Super Modern Browsers */
}

@font-face {
  font-family: "Objective";
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url(../../fonts/Objective/Objective-Bold.woff2) format("woff2"); /* Super Modern Browsers */
}


// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: $headings-letter-spacing;
}

h1, .h1, 
.display-1, .display-2, .display-3, .display-4 {
  line-height: $display-line-height;
}

@include media-breakpoint-down(sm) {

  // Headings

  h1, .h1 { font-size: $h1-font-size-sm }
  h2, .h2 { font-size: $h2-font-size-sm }
  h3, .h3 { font-size: $h3-font-size-sm }
  h4, .h4 { font-size: $h4-font-size-sm }
  h5, .h5 { font-size: $h5-font-size-sm }
  h6, .h6 { font-size: $h6-font-size-sm }
  
  // Display

  .display-3 { font-size: $display3-size-sm }
  .display-4 { font-size: $display4-size-sm }
}


// Blockquote

.blockquote {
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 1.5rem;
  border-left: 1px solid $primary;

  > footer {
    font-size: $font-size-sm;
  }
}


// Code

.code {
  max-height: 20rem;
}


// Lead

.lead {
  line-height: $lead-line-height;
}

h6.text-vertical {
  position: absolute;
  top: 75%;
  left: 100%;
  width: 85%;
  opacity: 0.75;
  transform-origin: bottom left;
  transform: rotate(-90deg);
  font-size: 0.6rem;
}

// List

.list-iconed {
  list-style-type: none;
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 0;
  text-align: left;
}

.list-iconed-item {
  display: flex;

  + .list-iconed-item {
    margin-top: 1em;
  }

  // Arrow

  &:before {
    content: '\f105';
    margin-right: 1em;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: $primary;
  }
}
